import React from "react"
import { Flex, Box, Text } from "@chakra-ui/core"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import ImageWithOverlay from "components/imageWithOverlaycolorOrChild"
import { getTag, getMonat } from "utilities/datum"

const EventTeaser = ({ date, title, subtitle, image, imageGray, path, id }) => {
  const imageAspectRatio = image && image.childImageSharp.fluid.aspectRatio

  let formattedDate = new Date(date)

  const monat = getMonat(formattedDate.getMonth())
  const tag = getTag(formattedDate.getDay())

  const datePart1 = `${formattedDate.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}. ${monat}`
  const datePart2 = `${tag} ${formattedDate.getHours().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}.${formattedDate.getMinutes().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })} Uhr`

  return (
    <Link to="/veranstaltungen/" state={{ prevPath: path, linkToID: id }}>
      <Flex
        direction="column"
        pr={["8em", "5em", "5em"]}
        css={css`
          .image-gray {
            visibility: visible;
          }
          @media (hover) {
            &:hover .image-gray {
              visibility: hidden;
            }
          }
        `}
      >
        <Box
          as="h6"
          color="red"
          fontSize={["5-5xl", "4xl", "3xl"]}
          pb={["0.2em", "0.1em", "0.2em"]}
          fontFamily="Overpass"
          lineHeight="1.2em"
        >
          <Text fontFamily="inherit" fontWeight="bold">
            {datePart1}
          </Text>
          {datePart2}
        </Box>
        <ImageWithOverlay
          image={image && image.childImageSharp.fluid}
          imageGray={imageGray && imageGray.childImageSharp.fluid}
          aspectRatio={imageAspectRatio}
        />
        {title && (
          <Box
            color="red"
            pt={["0.6em", "0.6em", "0.7em"]}
            fontSize={["5-5xl", "4xl", "3xl"]}
            lineHeight="1.25em"
          >
            {title}
          </Box>
        )}
        {subtitle && (
          <Box color="green" fontSize={["5-5xl", "4xl", "3xl"]}>
            {subtitle}
          </Box>
        )}
      </Flex>
    </Link>
  )
}

export default EventTeaser
