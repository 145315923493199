import React, { useMemo } from "react"
// import { Link } from "gatsby"
import { useTranslation, Link, useI18next } from "gatsby-plugin-react-i18next"

import Article from "containers/article"

const LinkTo = ({ path, data, linkTo }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const EN = language === "en"

  const getItems = selector => {
    return (
      {
        Das_Haus: {
          dataSelector: "dasHau",
          link: t("das-haus"),
        },
        Impressum: {
          dataSelector: "impressum",
          link: t("impressum"),
        },
        Forschung: {
          dataSelector: "forschung",
          link: t("forschung"),
        },
        Buchhandlung: {
          dataSelector: "buchhandlung",
          link: t("buchhandlung"),
        },
        Kontakt: {
          dataSelector: "kontakt",
          link: t("kontakt"),
        },
        Datenschutz: {
          dataSelector: "datenschutz",
          link: t("datenschutz"),
        },
        Freundeskreis: {
          dataSelector: "freundeskrei",
          link: t("freundeskreis"),
        },
        Fundraising: {
          dataSelector: "fundraising",
          link: t("fundraising"),
        },
        Veranstaltungen: {
          dataSelector: "veranstaltungen",
          link: t("veranstaltungen"),
        },
        Angebote_des_Hauses: {
          dataSelector: "angeboteDesHause",
          link: t("angebote-des-hauses"),
        },
        Literaturwettbewerb: {
          dataSelector: "literaturwettbewerb",
          link: t("literaturwettbewerb"),
        },
        Galerie: {
          dataSelector: "galeriePage",
          link: t("galerie"),
        },
      }[selector] || {
        Das_Haus: {
          dataSelector: "dasHau",
          link: t("das-haus"),
        },
      }
    )
  }

  const getInfo = useMemo(() => {
    const items = getItems(linkTo)
    const selector = items.dataSelector
    return {
      info: data[selector] && data[selector][EN ? "teaser_en" : "teaser"],
      link: items.link,
    }
  }, [data, linkTo])

  const link = getInfo.link
  const info = getInfo.info
  const { title, subtitle, cover } = info || {}

  return (
    <Link to={`/${link}/`} state={{ prevPath: path }}>
      <Article
        title={title && title}
        text={subtitle && subtitle}
        image={cover && cover.imageFile && cover.imageFile}
        imageGray={cover && cover.imageFileGray && cover.imageFileGray}
        textColor="green"
        alt={cover && cover.alternativeText}
        copyright={cover && cover.caption}
      />
    </Link>
  )
}

export default LinkTo
